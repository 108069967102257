import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Menu, MenuItem, Fade } from '@material-ui/core';
import clsx from 'clsx';

import { BULK_IMPORT_HELPERS, BULK_IMPORT_CONSTANTS } from '~/legacy/utils';
import {
  Button,
  ExpandLessIcon,
  ExpandMoreIcon,
  TransparentScrollOverlay,
  BuildingIcon,
  LockIcon,
} from '~/legacy/components';

import ModalTitle from './ModalTitle';

const MODAL_WIDTH_PX = 578;

const useStyles = makeStyles({
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  contentSmall: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: `${MODAL_WIDTH_PX}px`,
    height: '100%',
    paddingBottom: '16px',
    paddingTop: '4px',
  },
  row: {
    height: '52px',
    width: '100%',
    borderTop: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: '1px solid #E0E0E0',
    },
  },
  buttonContainer: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  firstButton: {
    marginLeft: 'auto',
  },
  secondButton: {
    marginLeft: '12px',
  },
  iconContainer: {
    width: '32px',
    marginRight: '8px',
    display: 'flex',
  },
  icon: {
    margin: 'auto',
  },
  matchedFieldText: {
    marginRight: '8px',
  },
  arrow: {
    color: '#111',
  },
});

const useConfirmFieldTypesStyles = makeStyles({
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  hoverIcon: {
    color: '#999',
  },
  contentSmall: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: `${MODAL_WIDTH_PX}px`,
    height: '548px',
    paddingBottom: '16px',
    overflowY: 'hidden',
    paddingTop: '4px',
  },
  pointer: {
    cursor: 'pointer',
  },
  row: {
    minHeight: '52px',
    width: '100%',
    borderTop: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: '1px solid #E0E0E0',
    },
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  firstButton: {
    marginLeft: 'auto',
  },
  secondButton: {
    marginLeft: '12px',
  },
  previewIcon: {
    marginLeft: 'auto',
  },
  scrollableFields: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
});

const ConfirmFieldRow = ({
  field,
  loadedListingFieldsObjModal,
  setLoadedListingFieldsObjModal,
}) => {
  const classes = useConfirmFieldTypesStyles();

  const [isHovering, setIsHovering] = useState(false);

  const isBuilding =
    field.fieldType.id === BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id;
  // Show the building icon when appropriate. This and lock should not overlap.
  const showBuilding = !!(
    (!isHovering && isBuilding) ||
    (isHovering && !field.reserved)
  );
  // Show a lock when hovering over a reserved field
  const showLock = !!(isHovering && field.reserved);

  return (
    <div
      className={clsx(classes.row, !field.reserved ? classes.pointer : '')}
      onClick={() => {
        if (!field.reserved) {
          setLoadedListingFieldsObjModal({
            ...loadedListingFieldsObjModal,
            [field.index]: {
              ...field,
              fieldType:
                field.fieldType.id ===
                BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
                  ? BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE
                  : BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING,
            },
          });
        }
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Typography variant="h3" className={classes.fieldLabel}>
        {field.displayName}
      </Typography>
      {showBuilding && (
        <BuildingIcon
          className={clsx(
            classes.previewIcon,
            isHovering && !isBuilding ? classes.hoverIcon : ''
          )}
        />
      )}
      {showLock && <LockIcon className={clsx(classes.previewIcon)} />}
    </div>
  );
};

function BulkImportConfirmFieldType({
  onClose,
  loadedListingFieldsObj,
  setLoadedListingFieldsObj,
}) {
  const classes = useConfirmFieldTypesStyles();

  // Track the fields and their types in the modal. We will save when modal closes
  const [loadedListingFieldsObjModal, setLoadedListingFieldsObjModal] =
    React.useState(loadedListingFieldsObj);
  const [
    orderedLoadedListingFieldsObjModal,
    setOrderedLoadedListingFieldsObjModal,
  ] = React.useState([]);

  useEffect(() => {
    setLoadedListingFieldsObjModal(loadedListingFieldsObj);
  }, [loadedListingFieldsObj]);

  useEffect(() => {
    // Reorder
    const newOrderedLoadedListingFieldsObjModal = [
      ...Object.values(loadedListingFieldsObjModal),
    ];
    newOrderedLoadedListingFieldsObjModal.sort((a, b) => a.index - b.index);
    setOrderedLoadedListingFieldsObjModal(
      newOrderedLoadedListingFieldsObjModal
    );
  }, [loadedListingFieldsObjModal]);

  return (
    <div className={classes.contentSmall}>
      <ModalTitle onClose={onClose} title="Confirm Building Fields" />
      <Typography variant="body1">
        We’ve categorized some of the fields in your CSV based on the name of
        the field. Click to add any additional fields we may have missed.
      </Typography>
      <div className={classes.scrollableFields}>
        <TransparentScrollOverlay
          paddingPx="0"
          hideScroll
          gradientHeightPx={60}
          bottomPaddingPx={40}
        >
          <div className={classes.fieldsContainer}>
            {orderedLoadedListingFieldsObjModal.map((field) => (
              <ConfirmFieldRow
                key={field.index}
                field={field}
                loadedListingFieldsObjModal={loadedListingFieldsObjModal}
                setLoadedListingFieldsObjModal={setLoadedListingFieldsObjModal}
              />
            ))}
          </div>
        </TransparentScrollOverlay>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.firstButton}
          color="secondary"
          onClick={onClose}
        >
          Skip
        </Button>
        <Button
          className={classes.secondButton}
          color="primary"
          onClick={() => {
            setLoadedListingFieldsObj(loadedListingFieldsObjModal);
            onClose();
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

const MatchableFieldRow = ({
  setFieldsWithMatchesModal,
  field,
  csvFieldsList,
  fieldsWithMatchesModal,
  mappableFieldIndex,
  mappedStandardFields,
  setMappedStandardFields,
}) => {
  const classes = useStyles();

  // For menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT_PX = 38;
  const displayMatch =
    field.match &&
    fieldsWithMatchesModal[field.match] &&
    fieldsWithMatchesModal[field.match].csvString
      ? fieldsWithMatchesModal[field.match].csvString
      : 'Match Field';

  return (
    <div className={classes.row}>
      <div className={classes.iconContainer}>
        {React.createElement(field.icon, { className: classes.icon })}
      </div>
      <Typography variant="h3">{field.displayName}</Typography>
      <div
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        style={{
          color: !field.match ? '#DD421A' : '#666',
          marginLeft: 'auto',
          padding: '6px 4px 6px 8px',
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
        }}
      >
        <Typography variant="h3" className={classes.matchedFieldText}>
          {displayMatch}
        </Typography>
        {anchorEl ? (
          <ExpandLessIcon className={classes.arrow} />
        ) : (
          <ExpandMoreIcon className={classes.arrow} />
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT_PX * 6.75,
          },
        }}
      >
        {[...csvFieldsList].map((csvField) => (
          <MenuItem
            key={csvField.displayName}
            style={{ height: `${ITEM_HEIGHT_PX}px` }}
            onClick={() => {
              BULK_IMPORT_HELPERS.matchMappableField(
                field,
                csvField,
                fieldsWithMatchesModal,
                setFieldsWithMatchesModal,
                mappedStandardFields,
                setMappedStandardFields,
                mappableFieldIndex
              );
              handleClose();
            }}
          >
            <Typography variant="h3">{csvField.csvString}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

function BulkImportFieldMatchModal({
  onClose,
  csvFields,
  fieldsWithMatches,
  setFieldsWithMatches,
  mappedStandardFields,
  setMappedStandardFields,
  loadedListingFieldsObj,
  setLoadedListingFieldsObj,
}) {
  const classes = useStyles();
  const csvFieldsList = csvFields || [];
  const [showConfirmFields, setShowConfirmFields] = useState(false);

  // Track the fields and their types. Matches update this. We will save when modal closes
  const [fieldsWithMatchesModal, setFieldsWithMatchesModal] =
    React.useState(fieldsWithMatches);

  // Add all of the fields with a default type of string
  useEffect(() => {
    setFieldsWithMatchesModal(fieldsWithMatches);
  }, [fieldsWithMatches]);

  return (
    <>
      {showConfirmFields ? (
        <Fade in={showConfirmFields}>
          <BulkImportConfirmFieldType
            onClose={onClose}
            loadedListingFieldsObj={loadedListingFieldsObj}
            setLoadedListingFieldsObj={setLoadedListingFieldsObj}
          />
        </Fade>
      ) : (
        <div className={classes.contentSmall}>
          <ModalTitle
            onClose={() => setShowConfirmFields(true)}
            title="Match Our Standard Fields"
          />
          <Typography variant="body1">
            We use a set of standard fields that we recommend all listings
            include. Click to link a column from your CSV to our standard fields
            below.
          </Typography>
          <div className={classes.fieldsContainer}>
            {mappedStandardFields.map((field, mappableFieldIndex) => (
              <MatchableFieldRow
                key={field.displayName}
                setMappedStandardFields={setMappedStandardFields}
                mappedStandardFields={mappedStandardFields}
                mappableFieldIndex={mappableFieldIndex}
                setFieldsWithMatchesModal={setFieldsWithMatchesModal}
                field={field}
                csvFieldsList={csvFieldsList}
                fieldsWithMatchesModal={fieldsWithMatchesModal}
              />
            ))}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.firstButton}
              color="secondary"
              onClick={() => {
                setShowConfirmFields(true);
              }}
            >
              Skip
            </Button>
            <Button
              className={classes.secondButton}
              color="primary"
              onClick={() => {
                setFieldsWithMatches(fieldsWithMatchesModal);
                setShowConfirmFields(true);
              }}
            >
              Match
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default BulkImportFieldMatchModal;
