import React from 'react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useLocalStorage } from '~/support/useLocalStorage'
import BuildingDetailPage from './BuildingDetailPage'
import BuildingDetailPageNew from './BuildingDetailPageNew'
import { FEATURE_FLAGS } from '~/legacy/utils/featureFlags'

export default function BuildingDetailPageWrapper({ match }) {
  const [isUseNewCustomFields] = useLocalStorage('USE_NEW_CUSTOM_FIELDS', false)
  const flagEnabled = useFeatureFlagEnabled(FEATURE_FLAGS.USE_NEW_CUSTOM_FIELDS)

  console.log('USE_NEW_CUSTOM_FIELDS', isUseNewCustomFields)
  console.log(
    `${FEATURE_FLAGS.USE_NEW_CUSTOM_FIELDS} flag enabled:`,
    flagEnabled
  )

  return flagEnabled || isUseNewCustomFields ? (
    <BuildingDetailPageNew match={match} />
  ) : (
    <BuildingDetailPage match={match} />
  )
}
