import { Severity, captureMessage } from '@sentry/browser'
import { useSelector } from 'react-redux'
import useSWR from 'swr'
import Api from 'rest-fetcher-redux'

export const useUserHeartbeat = () => {
  const user = useSelector((store) => store.user ?? {})

  useSWR(`/heartbeat/${user.id}`, {
    refreshInterval: 1000 * 60 * 5,

    fetcher: async () => {
      if (user.loggedIn) {
        const url = `${process.env.APP_API_URL}/users/${user.id}/heartbeat/`
        const response = await fetch(url, Api.baseOptions)
        if (response.status === 401) {
          localStorage.clear('token')
          location.reload()
        }
      }
    },

    onError: (error) => {
      captureMessage('User Heartbeat Fetch Failed', {
        level: Severity.Warning,
        extra: { error_message: error.message },
        tags: { error_name: error.name },
      })
    },
  })
}
