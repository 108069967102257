import { useEffect, useState } from 'react'
import { createDispatcher } from '~/libraries/messaging'
import { listen } from './listen'
import { valueOf } from './valueOf'

const dispatcher = createDispatcher()

export const useLocalStorage = (key, initial) => {
  const [value, setValue] = useState(() => {
    const cache = localStorage.getItem(key)
    return cache === null ? valueOf(initial) : JSON.parse(cache)
  })

  const saveValue = (newValue) => {
    const resolved = valueOf(newValue, value)
    localStorage.setItem(key, JSON.stringify(resolved))
    dispatcher.send({ key, value: resolved })
  }

  useEffect(() => {
    return listen(window, 'storage', (event) => {
      if (event.key === key && event.newValue) {
        setValue(JSON.parse(event.newValue))
      }
    })
  }, [key, setValue])

  useEffect(() => {
    return dispatcher.subscribe((event) => {
      if (event.key === key) {
        setValue(event.value)
      }
    })
  }, [key, setValue])

  return [value, saveValue]
}
