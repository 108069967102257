import { fetchApi } from './rootApi'
import { redirectOn401 } from './helpers'
import { formatId, SEGMENT_PARAM_NAMES } from '../utils'
import { BUILDING_INTEREST_LOOKUP } from '../consts'

const SURVEYS_ENDPOINT_ROOT = 'surveys'

export const surveyApi = {
  getSurvey: async ({ surveyId, page = null, skipAuth = false }) => {
    const promise = fetchApi({
      endpoint: `${SURVEYS_ENDPOINT_ROOT}/${surveyId}/?${new URLSearchParams({
        page,
      })}`,
      skipAuth,
    })
    promise.catch((error) => skipAuth && redirectOn401(error.response))
    return promise
  },

  updateSurveyBuildingStatuses: async ({ surveyId, buildingIds, interest }) =>
    fetchApi({
      endpoint: `${SURVEYS_ENDPOINT_ROOT}/${surveyId}/update_survey_building_statuses/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ building_ids: buildingIds, interest }),
      },
      trackingArgs: {
        name: 'Update Survey Building Interests',
        metadata: {
          [SEGMENT_PARAM_NAMES.BUILDING_IDS]: buildingIds.map((id) =>
            formatId(id)
          ),
          [SEGMENT_PARAM_NAMES.SURVEY_ID]: formatId(surveyId),
          [SEGMENT_PARAM_NAMES.SURVEY_BUILDING_INTEREST]:
            BUILDING_INTEREST_LOOKUP[interest],
        },
      },
    }),

  updatePartial: async ({ surveyId, partial }) =>
    fetchApi({
      endpoint: `${SURVEYS_ENDPOINT_ROOT}/${surveyId}/`,
      fetchArgs: {
        method: 'PATCH',
        body: JSON.stringify(partial),
      },
    }),

  setFieldOrder: async ({ surveyId, fieldOrder }) =>
    fetchApi({
      endpoint: `${SURVEYS_ENDPOINT_ROOT}/${surveyId}/set_field_order/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          field_order: fieldOrder,
        }),
      },
    }),
}
