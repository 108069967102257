import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Api from 'rest-fetcher-redux'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  Hidden,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { firstBy } from 'thenby'
import { setSurvey } from '~/legacy/store/actions/viewSurvey'
import {
  Button,
  AutocompleteChip,
  Loading,
  SurveyEmailPreview,
  TableClearIcon,
  SquareCheckbox,
  TextSelectField,
  PreviewIcon,
} from '~/legacy/components'
import {
  getUserTypeDisplayString,
  copyTextToClipboard,
  useLogoUrl,
  isEmailAddress,
} from '~/legacy/utils'
import { toast } from '~/legacy/utils/notifications'
import { useSegment } from '~/legacy/utils/hooks'
import ModalTitle from './ModalTitle'

// eslint-disable-next-line
import { userTypes, LEASE_PROJECT_BY_ID } from '~/legacy/consts'

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '16px',
    marginTop: '36px',
  },
  content: {
    backgroundColor: 'white',
    outline: 'none',
    width: 500,
    height: 550,
    display: 'flex',
    flexDirection: 'column',
  },
  contentSmall: {
    backgroundColor: 'white',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    height: '225px',
  },
  email: {
    flex: 2,
  },
  icon: {
    padding: 0,
    marginTop: 2,
    color: '#3B4144',
  },
  inviteButton: {
    marginLeft: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  role: {
    flex: 1,
  },
  hint: {
    flex: 1,
  },
  anyoneView: {
    color: theme.custom.secondaryText.color,
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  rightButtons: {
    display: 'flex',
    marginLeft: 'auto',
  },
  cancelButton: {
    marginRight: '10px',
  },
  button: {
    minWidth: '140px',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  optionEmail: {
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  optionRole: {
    display: 'flex',
    marginLeft: 'auto',
    color: 'gray',
  },
  sendButtonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  infoDisplayContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: 'auto',
    overflow: 'auto',
  },
  head: {
    letterSpacing: '.4px',
    color: '#666666',
  },
  body: {
    letterSpacing: '.1px',
    fontSize: '14px',
    lineHeight: '21px',
  },
  tableClearIcon: {
    color: '#e0e0e0',
    cursor: 'pointer',
    transform: 'translate(0, 3px)',
  },
  emailBodySettings: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  emailBodyTextAreaDisabled: {
    backgroundColor: '#f9f9f9',
    '& > div': {
      color: '#111111 !important',
      '& > fieldset': {
        border: 'none',
      },
    },
  },
  notifyPeopleCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 12px 12px 8px',
  },
  notifyPeopleCheckbox: {
    color: '#111111',
    marginRight: '4px',
  },
  previewButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabledPreviewButton: {
    opacity: '0.26',
    cursor: 'default',
  },
  previewLabel: {
    marginLeft: '4px',
    lineHeight: '24px',
  },
  visibilitySelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '44px',
  },
  visibilitySelector: {
    height: '24px',
  },
  visibilitySelectorLabel: {
    color: '#666666',
  },
  emailPreview: {
    width: '100%',
    paddingTop: '40px',
  },
  autocompleteChipLabel: {
    paddingRight: '8px',
  },
}))

const ENTER_EMAIL_STATE = 1
const SELECTED_EMAIL_STATE = 2
const PREVIEW_EMAIL_STATE = 3

function NewUserModal({ onClose, userOptions, setUserOptions, fetchSurvey }) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const survey = useSelector((s) => s.pages.viewSurvey.survey)
  const surveyListings = useSelector((s) => s.pages.viewSurvey.surveyListings)
  const user = useSelector((s) => s.user)
  const [emailValues, setEmailValues] = useState(null)
  const [emailInputValue, setEmailInputValue] = useState('')
  const [errorText, setErrorText] = useState('')
  const [emailBody, setEmailBody] = useState('')
  // Whether or not we requested and are awaiting response on inviting user
  const [requestingInvite, setRequestingInvite] = useState(false)
  const [sharedUsers, setSharedUsers] = useState(null)
  const autocompleteInputRef = useRef(null)
  const [modalState, setModalState] = useState(ENTER_EMAIL_STATE)
  const [userToRemove, setUserToRemove] = useState({})
  const [notifyPeople, setNotifyPeople] = useState(true)
  const segment = useSegment()

  const hasEmailValues = emailValues && emailValues.length

  const surveyVisibilityOptions = {
    0: 'Private Link',
    1: 'Public Link: Email Required',
    2: 'Public Link: No Restrictions',
  }
  const surveyVisibilityLabels = {
    0: 'Only people added can open this survey',
    1: 'Anyone who provides an email can view',
    2: 'Anyone with this link can view',
  }

  useEffect(() => {
    setModalState(hasEmailValues ? SELECTED_EMAIL_STATE : ENTER_EMAIL_STATE)
  }, [emailValues])

  useEffect(() => {
    // Whenever the shared users change, make sure to remove/add the owner properly
    //   and sort them for the autocomplete
    let newSharedUsers = sharedUsers
    if (newSharedUsers == null && survey && survey.users) {
      newSharedUsers = [...survey.users]

      // Add the ownwer to the list as an owner, not just a shared broker
      if (survey.owner) {
        newSharedUsers = newSharedUsers.filter(
          (sharedUser) => sharedUser.id !== survey.owner.id
        )
        newSharedUsers.push({ ...survey.owner, isOwner: true })
      }
    }
    // Sort the shared table users to show owner first, then alphabetical
    if (newSharedUsers) {
      newSharedUsers = newSharedUsers.sort(
        firstBy((a, b) => {
          let comp = 0
          if (a.isOwner && b.isOwner) {
            comp = 0
          } else if (a.isOwner) {
            comp = -1
          } else if (b.isOwner) {
            comp = 1
          }
          return comp
        }).thenBy((a, b) => {
          return a.username.localeCompare(b.username)
        })
      )
    }
    setSharedUsers(newSharedUsers)
  }, [survey, sharedUsers])

  const logoUrl = useLogoUrl()
  const sharedUserOptions = sharedUsers || []

  const clearEmailOptions = () => {
    setEmailValues(null)
    setEmailInputValue('')
    setEmailBody('')
    setErrorText('')
    if (autocompleteInputRef.current) {
      autocompleteInputRef.current.focus()
    }
  }

  const inviteUsers = () => {
    // Invite a user to the survey. If it's a new client user, we will also create
    //   a new unconfirmed user for them
    setRequestingInvite(true)
    Api.inviteUsersToProject({
      body: {
        users: emailValues,
        survey_id: survey.id,
        send_emails: notifyPeople,
        email_body: emailBody,
        color: theme.palette.primary.main,
        color_hover: theme.palette.primary.dark,
        logo_url: logoUrl,
      },
    })
      .then((res) => {
        // Remove invited users from autocomplete options of un-shared users
        setUserOptions(
          userOptions.filter((option) => {
            return !emailValues.some((ev) => option.email === ev.email)
          })
        )

        // Add invited users to the shared users
        // TODO: Have the endpoint return either the user or all of the users here
        //   so we don't have to mock it. This is potentially dangerous depending on what else
        //   is using the redux data and looking for other customer attributes...
        const newSharedUserOptions = [...sharedUserOptions].concat(
          res
            .map((inviteResult) => inviteResult.data.user || false)
            .filter((u) => !!u)
        )

        dispatch(setSurvey({ ...survey, users: newSharedUserOptions }))

        onClose()

        res.forEach(
          (inviteResult) =>
            inviteResult.data &&
            inviteResult.data.user &&
            segment.trackShareSurvey(
              survey.id,
              inviteResult.data.user.id,
              inviteResult.data.user.email,
              inviteResult.data.user.user_type,
              !!emailBody
            )
        )
      })
      .finally(() => setRequestingInvite(false))
  }

  const getUserTypeDisplay = (userType, isOwner) => {
    return isOwner ? 'Owner' : getUserTypeDisplayString(userType, true)
  }

  const filter = createFilterOptions()

  if (userToRemove.id) {
    return (
      <div className={classes.contentSmall}>
        <ModalTitle onClose={onClose} title="Are You Sure?" />
        <Typography variant="body1">
          {`Are you sure you want to remove ${userToRemove.email}?
            They will not be able to access this survey anymore.`}
        </Typography>
        <div className={classes.actionsContainer}>
          <div className={classes.rightButtons}>
            <Button
              className={`${classes.cancelButton} ${classes.button}`}
              color="primary"
              variant="text"
              shrinkButton
              onClick={onClose}
            >
              Cancel
            </Button>
            <div className={classes.sendButtonWrapper}>
              <Button
                className={classes.button}
                color="primary"
                shrinkButton
                onClick={() =>
                  Api.removeUserFromSurvey({
                    survey_id: survey.id,
                    body: {
                      user_id: userToRemove.id, // this is actually user.id
                    },
                  }).then(() => {
                    // add back the user as an invite option
                    setUserOptions(userOptions.concat(userToRemove))
                    fetchSurvey()
                    onClose()
                  })
                }
                disabled={requestingInvite}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.content}>
      <ModalTitle onClose={onClose} title="Share Survey" />

      <div>
        {/* We force this mulitselect to behave as a single input since that's the only way to get
        the chips to display rather than text */}
        <Autocomplete
          freeSolo
          multiple
          autoSelect
          limitTags={1}
          disabled={requestingInvite}
          className={classes.email}
          value={hasEmailValues ? emailValues : []}
          inputValue={emailInputValue}
          onInputChange={(event, value) => {
            // Make this a single-select by preventing typing when an option has already been
            //   selected
            if (event) {
              setErrorText(null)
              setEmailInputValue(value)
            }
          }}
          onChange={(event, newValueArray) => {
            const newValue = newValueArray.pop()
            let newEmailValue
            let newErrorText = null

            // User typed something in!
            if (typeof newValue === 'string') {
              // Let's make sure they aren't trying to type in a value already in
              //   the options.
              const find = userOptions.find((u) => u.email === newValue)
              if (find) {
                newEmailValue = find
              } else {
                // It's a new value, make a new tenant option
                newEmailValue = {
                  email: newValue,
                  inputValue: newValue,
                  userType: userTypes.tenant,
                  newUser: true,
                }
              }
            } else {
              // User selected an existing option or pressed enter
              newEmailValue = newValue
            }

            // Check that the email is a valid email, and that the survey hasn't already been shared with user
            if (newEmailValue && !isEmailAddress(newEmailValue.email)) {
              newErrorText = 'Not a valid email address'
              setEmailInputValue(newEmailValue.email)
            } else if (
              newEmailValue &&
              sharedUserOptions.some((u) => u.email === newEmailValue.email)
            ) {
              newErrorText = 'Survey has already been shared with this user'
            } else {
              // If there's no error, then update the invitee email values
              const base = emailValues || []
              setEmailValues(base.concat(newEmailValue))
            }

            setErrorText(newErrorText)
          }}
          filterOptions={(options, params) => {
            // Don't show any options until the user starts typing
            // Also make this multiselect a singleselect by filterint out every single option
            //   if an option has already been selected
            if (!emailInputValue) {
              return []
            }

            // filter out options we have already selected
            let newOptions = [...options].filter((option) =>
              hasEmailValues
                ? !emailValues.some((ev) => ev.email === option.email)
                : true
            )
            // Suggest the creation of a new value if it doesn't already exist
            if (
              params.inputValue !== '' &&
              !userOptions.some((u) => u.email === params.inputValue) &&
              isEmailAddress(params.inputValue)
            ) {
              newOptions = [
                ...newOptions,
                {
                  email: params.inputValue,
                  inputValue: params.inputValue,
                  userType: userTypes.tenant,
                  newUser: true,
                },
              ]
            }

            return filter([...newOptions], params)
          }}
          options={userOptions.sort(
            firstBy((a, b) => a.email.localeCompare(b.email))
          )}
          getOptionLabel={(option) => {
            // Option label for the selected input option. Not really used visually since we
            //   are using renderTags, but still required
            let email
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              email = option
            } else if (option.inputValue) {
              // Add new tenant option created dynamically
              email = option.inputValue
            } else {
              // Regular option
              ;({ email } = option)
            }
            return email
          }}
          renderOption={(option) => {
            // Render the option row in the dropdown
            return (
              <div className={classes.optionContainer}>
                <div className={classes.optionEmail}>{option.email}</div>
                <div className={classes.optionRole}>
                  {getUserTypeDisplayString(option.userType, true)}
                </div>
              </div>
            )
          }}
          renderTags={(value, getTagProps) => {
            // Render the selected option(s) chip
            return value.map((option, index) => (
              <AutocompleteChip
                color="primary"
                chipProps={{
                  onDelete: () =>
                    setEmailValues(
                      emailValues.filter((val) => val.email !== option.email)
                    ),
                }}
                option={option.email}
                classes={{ label: classes.autocompleteChipLabel }}
                {...getTagProps({ index })}
              />
            ))
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={autocompleteInputRef}
              autoFocus
              label="Email"
              variant="outlined"
              placeholder={
                !hasEmailValues ? 'Add clients and team members' : ''
              }
              helperText={errorText}
              error={!!errorText}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>

      {/* Either the shared users table, or the email body text */}
      <div
        className={`${classes.infoDisplayContainer} ${classes.tableContainer}`}
      >
        {/* Shared users table displaying users in the survey and their role */}
        <Hidden xlDown={modalState !== ENTER_EMAIL_STATE}>
          <TableContainer>
            <Table
              stickyHeader
              size="medium"
              style={{ marginTop: '20px', tableLayout: 'fixed' }}
            >
              <colgroup>
                <col style={{ width: '80%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '8%', paddingLeft: '0' }} />
              </colgroup>
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell size="small" align="left">
                    <Typography className={classes.head} variant="h5">
                      SHARED WITH
                    </Typography>
                  </TableCell>
                  <TableCell size="small" align="left">
                    <Typography className={classes.head} variant="h5">
                      ROLE
                    </Typography>
                  </TableCell>
                  <TableCell size="small">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sharedUserOptions.map((surveyUser) => (
                  <TableRow key={surveyUser.id}>
                    <TableCell align="left">
                      <Typography className={classes.body} variant="body2">
                        {surveyUser.username || surveyUser.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.body} variant="body2">
                        {getUserTypeDisplay(
                          surveyUser.user_type,
                          surveyUser.isOwner
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {!surveyUser.isOwner && survey.owner.id === user.id && (
                        <TableClearIcon
                          className={classes.tableClearIcon}
                          onClick={() => setUserToRemove(surveyUser)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>

        {/* Email body text area */}
        <Hidden xlDown={modalState !== SELECTED_EMAIL_STATE}>
          <div className={classes.emailBodySettings}>
            <div className={classes.notifyPeopleCheckboxContainer}>
              <SquareCheckbox
                className={classes.notifyPeopleCheckbox}
                showHover={false}
                checked={notifyPeople}
                onClick={() => setNotifyPeople(!notifyPeople)}
              />
              <Typography variant="h3">Notify People</Typography>
            </div>
            <TextField
              className={clsx(
                !notifyPeople && classes.emailBodyTextAreaDisabled
              )}
              variant="outlined"
              fullWidth
              rows={14}
              multiline
              disabled={requestingInvite || !notifyPeople}
              onChange={(event) => setEmailBody(event.target.value)}
              placeholder={
                notifyPeople
                  ? 'Add your message'
                  : 'To add a message, select Notify People above.'
              }
              value={emailBody}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </Hidden>

        <Hidden xlDown={modalState !== PREVIEW_EMAIL_STATE}>
          <div className={classes.emailPreview}>
            <SurveyEmailPreview
              brokerage={user.company.name}
              brokerEmail={user.email}
              brokerFirstName={user.firstName}
              brokerLastName={user.lastName}
              surveyName={survey.name}
              listings={surveyListings.map((sl) => sl.listing)}
              emailBody={emailBody}
            />
          </div>
        </Hidden>
      </div>

      {/* Bottom Action Buttons */}
      <div className={classes.actionsContainer}>
        {/* Buttons before selecting an option */}
        <Hidden xlDown={modalState !== ENTER_EMAIL_STATE}>
          <div className={classes.visibilitySelectorContainer}>
            <TextSelectField
              className={classes.visibilitySelector}
              color="black"
              onChange={(newStatus) =>
                Api.updateProject({
                  id: survey.id,
                  body: {
                    ...survey,
                    privacy_status: newStatus,
                  },
                })
                  .then((data) => {
                    if (data.id) {
                      dispatch(setSurvey(data))
                    }
                  })
                  .catch(() =>
                    toast('Error saving permissions', { appearance: 'error' })
                  )
              }
              options={surveyVisibilityOptions}
              showLabel={false}
              showSelectedOptionIcon
              selectedValue={survey.privacy_status}
              width="268px"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            />
            <Typography
              className={classes.visibilitySelectorLabel}
              variant="body1"
            >
              {surveyVisibilityLabels[survey.privacy_status]}
            </Typography>
          </div>
          <Button
            className={classes.inviteButton}
            color="primary"
            onClick={() =>
              copyTextToClipboard(
                `${window.location.origin}${LEASE_PROJECT_BY_ID.replace(
                  ':id',
                  survey.id
                )}`
              )
            }
          >
            Copy Survey Link
          </Button>
        </Hidden>

        {/* Buttons after selecting an option */}
        <Hidden xlDown={modalState === ENTER_EMAIL_STATE}>
          <div className={classes.buttonsRow}>
            <div
              className={clsx(
                classes.previewButton,
                (requestingInvite || !notifyPeople) &&
                  classes.disabledPreviewButton
              )}
              onClick={() =>
                !requestingInvite &&
                notifyPeople &&
                setModalState(
                  modalState === PREVIEW_EMAIL_STATE
                    ? SELECTED_EMAIL_STATE
                    : PREVIEW_EMAIL_STATE
                )
              }
            >
              <PreviewIcon />
              <Typography className={classes.previewLabel} variant="h3">
                Preview
              </Typography>
            </div>
            <div className={classes.rightButtons}>
              <Button
                className={`${classes.cancelButton} ${classes.button}`}
                color="secondary"
                shrinkButton
                onClick={clearEmailOptions}
              >
                Cancel
              </Button>
              <div className={classes.sendButtonWrapper}>
                <Button
                  className={classes.button}
                  color="primary"
                  shrinkButton
                  onClick={inviteUsers}
                  disabled={requestingInvite}
                >
                  Invite
                </Button>
                <Loading
                  size={24}
                  isLoading={requestingInvite}
                  className={classes.buttonProgress}
                />
              </div>
            </div>
          </div>
        </Hidden>
      </div>
    </div>
  )
}

export default NewUserModal
