import axios from 'axios'
import fileDownload from 'js-file-download'
import Api from 'rest-fetcher-redux'
import {
  captureSentryMessageError,
  captureSentryMessageWarning,
} from '~/legacy/utils'
import { Segment } from './hooks/segment'

const logSaveListing = (segment, result, building, surveyId) => {
  if (result && result.id) {
    const additionalFields = result.custom_fields
    Segment.trackCreateListing({
      listingId: result.id,
      buildingId: building.id,
      numAdditionalFields: additionalFields
        ? Object.keys(additionalFields).length
        : 0,
      surveyId,
    })
    if (surveyId) {
      Segment.trackAddListingToSurvey(surveyId, result.id)
    }
  }
}

export const ApiHelper = {
  // Update the survey listing status
  updateSurveyListingStatus: ({
    surveyListingId,
    newStatus,
    colors,
    statusChange,
  }) => {
    const promise = Api.updateSurveyListingStatus({
      id: surveyListingId,
      body: { status: newStatus, colors },
    })

    promise.then(() => {
      Segment.trackSurveyListingStatus(statusChange)
    })

    return promise
  },

  updateSurveyListingStatuses: ({ surveyListings, colors }) => {
    const promise = Api.updateSurveyListingStatuses({
      body: { survey_listings: surveyListings, colors },
    })

    // TODO: Batch request?
    promise.then(() => {
      surveyListings.forEach((surveyListing) =>
        Segment.trackSurveyListingStatus(surveyListing.status_change)
      )
    })

    return promise
  },

  // Assign a listing to a project
  assignListingToProject: (surveyId, listingId) => {
    const promise = Api.assignListingToProject({
      body: {
        listing_id: listingId,
        survey_id: surveyId,
      },
    })

    promise.then(() => {
      Segment.trackAddListingToSurvey(surveyId, listingId)
    })

    return promise
  },

  addListingsBulk: ({
    listings,
    bulkImportCsvFileId = null,
    surveyId = null,
  }) => {
    const promise = Api.saveListingsBulk({
      body: {
        listings,
        bulk_import_csv_file_id: bulkImportCsvFileId,
      },
    })

    // TODO: make batch call
    promise.then((results) => {
      if (results) {
        results.forEach((result) =>
          logSaveListing(Segment, result, result.building.id, surveyId)
        )
      }
    })

    return promise
  },

  // Create a listing, which means tracking the create as well as
  //   optionally the add to a survey
  addListing: ({ listing, building, surveyId = null }) => {
    const promise = Api.saveListing({
      body: {
        listing,
        building,
      },
    })

    promise.then((result) => {
      logSaveListing(Segment, result, result.building.id, surveyId)
    })

    return promise
  },

  deleteListingSimple: ({ listingId, buildingId }) => {
    const promise = Api.deleteListingSimple({ id: listingId })
    promise.then((res) => {
      if (res.detail === 'Deleted') {
        Segment.trackDeleteListingSimple(listingId, buildingId)
      }
    })
    return promise
  },

  // SDP quick entry
  quickEntryCreateListing: ({ addressParts, surveyId }) => {
    const promise = Api.quickEntryCreateListing({
      body: {
        address_parts: addressParts,
        survey_id: surveyId,
      },
    })

    promise.then((result) => {
      if (result && result.data) {
        const { buildingId, surveyListing } = result.data
        if (surveyListing) {
          Segment.trackCreateListing({
            listingId: surveyListing.listing.id,
            buildingId,
            surveyId,
          })
          Segment.trackAddListingToSurvey(surveyId, surveyListing.listing.id)
        }
      }
    })

    return promise
  },

  exportSurvey: ({
    survey,
    html,
    cssString = '',
    format = 'letter',
    landscape = false,
  }) =>
    axios
      .post(
        `${process.env.APP_API_URL}/surveys/${survey.id}/export/`,
        {
          html,
          cssString,
          format,
          landscape,
        },
        {
          responseType: 'blob',
          headers: {
            Authorization: Api.baseOptions.headers.Authorization,
          },
        }
      )
      .then((res) =>
        fileDownload(res.data, `${survey.name.replaceAll(' ', '')}.pdf`)
      )
      .catch(() => {}),

  uploadUserAvatar: ({ file, userId }) => {
    const formData = new FormData()
    const header = Api.baseOptions.headers
    formData.append('file', file, file.name)
    formData.append('user_id', userId)
    const promise = fetch(`${process.env.APP_API_URL}/user_avatar_image/`, {
      method: 'POST',
      headers: {
        Authorization: header.Authorization,
      },
      body: formData,
    }).then((response) => response.json())

    return promise
  },

  removeUserAvatar: ({ fileId }) => {
    const header = Api.baseOptions.headers
    const promise = fetch(
      `${process.env.APP_API_URL}/user_avatar_image/${fileId}/`,
      {
        method: 'DELETE',
        headers: {
          Authorization: header.Authorization,
        },
      }
    )

    return promise
  },

  updloadCompanySkinFaviconImage: ({ file }) => {
    const formData = new FormData()
    const header = Api.baseOptions.headers
    formData.append('file', file, file.name)
    const promise = fetch(
      `${process.env.APP_API_URL}/company_skin_favicon_image/`,
      {
        method: 'POST',
        headers: {
          Authorization: header.Authorization,
        },
        body: formData,
      }
    ).then((response) => response.json())

    return promise
  },

  updateUserData: (data, userId, password) =>
    Api.saveAccountSettings({
      id: userId,
      body: {
        ...data,
        password,
      },
    }),

  createBroker: (userFields = {}, logoFields = {}, file) => {
    const formData = new FormData()
    if (file) {
      formData.append('file', file, file.name)
    }
    Object.entries({
      ...userFields,
      ...logoFields,
    }).forEach(([key, value]) => formData.append(key, value))

    return fetch(`${process.env.APP_API_URL}/useradmin/create_broker/`, {
      method: 'POST',
      headers: {
        Authorization: Api.baseOptions.headers.Authorization,
      },
      body: formData,
    }).then((response) =>
      Promise.all([Promise.resolve(response), response.json()])
    )
  },

  transferSurveyOwnership: ({
    surveyIds,
    transferToUserId,
    logoFields = {},
  }) => {
    return fetch(`${process.env.APP_API_URL}/surveys/transfer_ownership/`, {
      method: 'POST',
      headers: {
        Authorization: Api.baseOptions.headers.Authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        survey_ids: surveyIds,
        transfer_to_user_id: transferToUserId,
        ...logoFields,
      }),
    }).then((response) =>
      Promise.all([Promise.resolve(response), response.json()])
    )
  },

  getSkinFromS3: ({ subDomain }) => {
    const bucket = process.env.APP_SKINS_BUCKET
    return fetch(
      `https://${bucket}.s3.us-east-2.amazonaws.com/${subDomain}.json`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) =>
        Promise.all([
          Promise.resolve(response),
          response.ok ? response.json() : response.text(),
        ])
      )
      .then(([response, data]) => {
        if (!response.ok) {
          if (response.status === 403) {
            captureSentryMessageWarning({
              message: 'Missing skin in S3',
              tags: {
                subDomain,
                bucket,
              },
              extras: {
                response_status: response.status,
                data,
              },
            })
          } else {
            captureSentryMessageError({
              message: 'Error fetching skin from S3',
              tags: {
                subDomain,
                bucket,
              },
              extras: {
                response_status: response ? response.status : '',
                data,
              },
            })
          }
        }
        return [response, data]
      })
      .catch((exception) => {
        captureSentryMessageWarning({
          message: 'Error parsing skin response from S3',
          tags: {
            subDomain,
            bucket,
          },
          extras: {
            exception,
          },
        })
        return [null, null]
      })
  },

  updateCompany: ({ companyId, companyDetails }) => {
    return fetch(`${process.env.APP_API_URL}/company/${companyId}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Api.baseOptions.headers.Authorization,
      },
      body: JSON.stringify(companyDetails),
    }).then((response) =>
      Promise.all([
        Promise.resolve(response),
        response.ok ? response.json() : response.text(),
      ])
    )
  },
}
