import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading({ children, isLoading, ...rest }) {
  if (isLoading) {
    return <CircularProgress {...rest} />;
  }
  return children || '';
}

export default Loading;
