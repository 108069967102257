import React from 'react'
import { ConfirmModalComponent } from '~/legacy/components'

// A modal to edit a project
export const DeleteSpaceFieldModal = ({
  fieldName,
  surveyName,
  deleteSpaceField,
  isCustomField,
  open,
  onClose,
}) => {
  const actionText = isCustomField ? 'Delete' : 'Clear'
  const text = `Are you sure you want to ${actionText.toLowerCase()} the ${fieldName} field?
  This will remove the field from all buildings in the ${surveyName} survey. This action can't be undone.`

  return (
    <ConfirmModalComponent
      ModalComponentProps={{
        open: !!open,
        onClose,
      }}
      onClose={onClose}
      onConfirm={async () => {
        try {
          onClose()
          await deleteSpaceField()
        } catch {
          // supress
        }
      }}
      title={`${actionText} Field`}
      confirmButtonLabel={actionText}
      text={text}
    />
  )
}

// Get the delete field modal and its open/closed state
export const useDeleteSpaceFieldModal = ({
  fieldName,
  surveyName,
  buildingName,
  isCustomField,
  deleteSpaceField,
  isBuildingField,
  onClose,
}) => {
  const [open, setOpen] = React.useState(false)

  const DeleteSpaceFieldModalComponent = (
    <DeleteSpaceFieldModal
      fieldName={fieldName}
      buildingName={buildingName}
      surveyName={surveyName}
      isCustomField={isCustomField}
      deleteSpaceField={deleteSpaceField}
      isBuildingField={isBuildingField}
      open={open}
      onClose={onClose}
    />
  )

  return {
    open,
    setOpen,
    DeleteSpaceFieldModalComponent,
  }
}
