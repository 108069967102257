import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Api from 'rest-fetcher-redux';
import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { setSurveyListingsAndBuildings } from '~/legacy/store/actions/viewSurvey';
import { ConfirmModalComponent, DeleteIcon } from '~/legacy/components';
import { BASE_ICON_STYLES } from './ButtonUtils';

const useStyles = makeStyles((theme) => ({
  ...BASE_ICON_STYLES(theme),
}));

const removeBuildingFromSurvey = ({
  dispatch,
  surveyListings,
  surveyBuildings,
  surveyId,
  buildingId,
}) => {
  Api.remove_survey_building({
    id: surveyId,
    body: {
      building_id: buildingId,
    },
  }).then((result) => {
    if (result) {
      dispatch(
        setSurveyListingsAndBuildings(
          surveyListings.filter(
            (surveyListing) => surveyListing.listing.building.id !== buildingId
          ),
          surveyBuildings.filter(
            (surveyBuilding) => surveyBuilding.building.id !== buildingId
          )
        )
      );
    }
  });
};

export const RemoveBuildingFromSurveyMenuItem = React.forwardRef(
  (
    {
      surveyId,
      buildingId,
      handleMenuClose,
      requireConfirm = false,
      label = 'Remove Building',
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const surveyListings = useSelector(
      (store) => store.pages.viewSurvey.surveyListings
    );
    const surveyBuildings = useSelector(
      (store) => store.pages.viewSurvey.surveyBuildings
    );

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItemRoot}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!requireConfirm && buildingId) {
            removeBuildingFromSurvey({
              dispatch,
              surveyListings,
              surveyBuildings,
              surveyId,
              buildingId,
            });
          }
          handleMenuClose(e);
        }}
        {...props}
      >
        <DeleteIcon className={classes.icon} />
        <Typography className={classes.text}>{label}</Typography>
      </MenuItem>
    );
  }
);

const ConfirmRemoveBuildingFromSurveyModal = ({
  open,
  closeModal,
  onConfirm,
  surveyName,
}) => (
  <ConfirmModalComponent
    ModalComponentProps={{
      open: !!open,
      onClose: closeModal,
    }}
    onClose={closeModal}
    onConfirm={onConfirm}
    title="Remove From Survey"
    confirmButtonLabel="Delete"
    text={`Are you sure you want to remove this building from the survey ${surveyName}? This action can’t be undone.`}
  />
);

export const useRemoveBuildingFromSurveyMenuItem = ({
  surveyId,
  surveyName,
  buildingId,
  handleMenuClose,
  redirect,
  label = 'Remove Building',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const surveyListings = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  );
  const surveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  );

  const RemoveBuildingFromSurveyMenuItemComponent = (
    <RemoveBuildingFromSurveyMenuItem
      requireConfirm
      surveyId={surveyId}
      buildingId={buildingId}
      onClick={() => {
        setOpen(true);
        handleMenuClose();
      }}
      handleMenuClose={handleMenuClose}
      label={label}
      {...props}
    />
  );

  const ConfirmRemoveBuildingFromSurveyModalComponent = (
    <ConfirmRemoveBuildingFromSurveyModal
      open={open}
      closeModal={() => setOpen(false)}
      onConfirm={() => {
        removeBuildingFromSurvey({
          dispatch,
          surveyListings,
          surveyBuildings,
          surveyId,
          buildingId,
        });
        setOpen(false);
        if (redirect) {
          history.push(redirect);
        }
      }}
      surveyName={surveyName}
    />
  );

  return {
    RemoveBuildingFromSurveyMenuItemComponent,
    ConfirmRemoveBuildingFromSurveyModalComponent,
  };
};

export default RemoveBuildingFromSurveyMenuItem;
