import React from 'react';
import { Chip } from '@material-ui/core';
import { withStyles, lighten } from '@material-ui/core/styles';
import { DeleteXIcon } from '~/legacy/components';

const AutocompleteChip = withStyles((theme) => ({
  chipRoot: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    fontFamily: 'Inter-SemiBold',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0.1px',
    color: '#111',
  },
  deleteIconChip: {
    color: theme.palette.primary.main,
    margin: '0 8px 0 0',
    width: 'unset',
    height: 'unset',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))(({ option, chipProps = {}, classes }) => {
  return (
    <Chip
      classes={{
        root: classes.chipRoot,
        deleteIcon: classes.deleteIconChip,
        label: classes.label,
      }}
      deleteIcon={<DeleteXIcon className={classes.deleteIconChip} />}
      label={option}
      {...chipProps}
    />
  );
});

export default AutocompleteChip;
