import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import * as Sentry from '@sentry/browser'

import { makeStyles } from '@material-ui/core/styles'
import { ErrorBoundary } from 'react-error-boundary'
import routes from '~/legacy/pages'
// import { useIntercom } from '~/support/useIntercom'

import { NotFound } from '~/pages/NotFound'

import {
  GeneralEncounteredError,
  PrivateRoute,
  PrrmissionEncounteredError,
  TopNav,
  TopNavTail,
} from '~/legacy/components'
import { LEASE_LOGIN } from '~/legacy/consts'
import { updateUserData } from '~/legacy/store'
import {
  RootPermissionError,
  captureSentryExceptionError,
} from '~/legacy/utils'
import { useIdentifyUser } from '~/legacy/utils/hooks'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  header: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transform: 'translate3d(0, 0, 200px)', // safari...
    display: () => (window.location.hash === '#export' ? 'none' : 'block'),
  },
  hideScroll: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflowY: 'auto',
  },
  root: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
}))

function CenterLayout({
  allowAnonymous,
  location,
  history,
  hideScroll = false,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((store) => store.user.loggedIn)
  const user = useSelector((store) => store.user)
  let redirect = ''

  // if we're not logged in but we're on a page that allows anonymous
  // browsing, set the user fields to reflect that we are anonymous
  if (!isLoggedIn && allowAnonymous && user.isAnonymous !== true) {
    dispatch(updateUserData({ isAnonymous: true }))
  }

  if (!isLoggedIn && !allowAnonymous) {
    // If not logged in, then send them to the log in page with a redirect parameter indicating this location so that the
    //   user can be brought back once they log in
    if (location.pathname) {
      let redirectAppend = location.pathname
      if (location.search) {
        redirectAppend += location.search
      }
      if (location.hash) {
        redirectAppend += location.hash
      }
      redirect = `${LEASE_LOGIN}?redirect=${encodeURIComponent(redirectAppend)}`
    }
    history.push(redirect)
  }

  useIdentifyUser({ user })
  // useIntercom({ user })

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: user.id,
      email: user.email,
    })
  })

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div id="globalHeader" className={classes.header}>
          {!location.pathname.includes('reset_password') &&
            !location.pathname.includes('create_password') && <TopNav />}
          <TopNavTail location={location} />
        </div>
        <main
          className={clsx(classes.main, hideScroll ? classes.hideScroll : '')}
        >
          <ErrorBoundary
            FallbackComponent={({ error }) => {
              return (
                <div style={{ paddingTop: '16px' }}>
                  {error instanceof RootPermissionError ? (
                    <PrrmissionEncounteredError />
                  ) : (
                    <GeneralEncounteredError />
                  )}
                </div>
              )
            }}
            onError={(error) => captureSentryExceptionError(error)}
          >
            {/* This is crucial to not start loading the page, only to hit another and potentially faster acting redirect...
            Not to mention show errors / half loaded pages... */}
            {!redirect ? (
              <Switch location={location}>
                {Object.values(routes)
                  .filter((route) => !route.isPublic)
                  .map(({ ...rest }) => (
                    <PrivateRoute key={rest.path} {...rest} />
                  ))}
                <Route component={NotFound} />
              </Switch>
            ) : (
              ''
            )}
          </ErrorBoundary>
        </main>
      </div>
    </div>
  )
}

CenterLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

CenterLayout.defaultProps = {}

export default memo(CenterLayout)
